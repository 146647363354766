<template>
    <v-col cols="auto">
        <v-card flat width="96">
            <slot/>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: "KurccFormSpacer"
}
</script>
